import React, { FC } from "react";
import {
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
} from "@material-ui/core";

import ButtonField from "../Fields/ButtonField";
import Row from "./Row";
import Col from "./Col";
import { useTranslation } from "react-i18next";

interface Props {
    open: boolean;
    title?: string;
    onClose: () => void;
    onSubmit?: () => void;
    submitBtnText?: string;
    onCancel?: () => void;
    cancelBtnText?: string;
    className?: string;
    maxWidth?: false | "lg" | "md" | "sm" | "xs" | "xl";
    fullWidth?: boolean;
}

const Modal: FC<Props> = ({
    open,
    title,
    onClose,
    onSubmit,
    submitBtnText,
    onCancel,
    cancelBtnText,
    className,
    maxWidth,
    fullWidth = false,
    ...props
}) => {
    const { t } = useTranslation();

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth={fullWidth}
            maxWidth={maxWidth || false}
        >
            <div className={className}>
                {(title) && (
                    <DialogTitle>
                        <Row>
                            <Col>
                                {title}
                            </Col>
                        </Row>
                    </DialogTitle>
                )}
                <DialogContent dividers>{props.children}</DialogContent>
                {(onSubmit || onCancel) && (
                    <DialogActions>
                        {onCancel && (
                            <ButtonField onClick={() => onCancel()}>
                                {cancelBtnText}
                            </ButtonField>
                        )}
                        {onSubmit && (
                            <ButtonField color="primary"  onClick={() => onSubmit()}>
                                {submitBtnText}
                            </ButtonField>
                        )}
                    </DialogActions>
                )}
            </div>
        </Dialog>
    );
};

export default Modal;
